import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const KGaikanTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "作業者", value: "worker" },
  { text: "受注番号", value: "acceptNo" },
  { text: "前工程作業番号", value: "preProcessNo" },
  { text: "仕様書No", value: "specificationNo" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "数量(m)", value: "suryo" },
  { text: "数量明細(m)", value: "suryoMeisai" },
  { text: "良・否", value: "goodOrBad" },
  { text: "処置：修理", value: "isRepairing" },
  { text: "処置：解体", value: "isDisassembly" },
  { text: "処置：廃棄/再製作", value: "isRemanufacture" },
  { text: "製品梱包", value: "isPackaging" },
  { text: "枝番", value: "branchNumber" },
  { text: "製品異常処置報告", value: "abnormality" },
  { text: "備考", value: "biko" }
] as const;

export class KGaikanTable {
  workDate: string;
  updateTs: string | null;
  worker: string | null;
  acceptNo: string | null;
  preProcessNo: string | null;
  specificationNo: string | null;
  itemName: string | null;
  size: string | null;
  suryo: number | null;
  suryoMeisai: string | null;
  goodOrBad: string | null;
  isRepairing: boolean;
  isDisassembly: boolean;
  isRemanufacture: boolean;
  isPackaging: boolean;
  branchNumber: string | null;
  abnormality: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;

  constructor(props: {
    workDate: string;
    worker: string | null;
    acceptNo: string | null;
    preProcessNo: string | null;
    specificationNo: string | null;
    itemName: string | null;
    size: string | null;
    suryo: number | null;
    suryoMeisai: string | null;
    goodOrBad: string | null;
    isRepairing: string | null;
    isDisassembly: string | null;
    isRemanufacture: string | null;
    isPackaging: string | null;
    branchNumber: string | null;
    abnormality: string | null;
    biko: string | null;
    denno: number | null;
    gyono: number | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.worker = props.worker;
    this.acceptNo = props.acceptNo;
    this.preProcessNo = props.preProcessNo;
    this.specificationNo = props.specificationNo;
    this.itemName = props.itemName;
    this.size = props.size;
    this.suryo = props.suryo;
    this.suryoMeisai = props.suryoMeisai;
    this.goodOrBad = props.goodOrBad;
    this.isRepairing = props.isRepairing === "1" ? true : false;
    this.isDisassembly = props.isDisassembly === "1" ? true : false;
    this.isRemanufacture = props.isRemanufacture === "1" ? true : false;
    this.isPackaging = props.isPackaging === "1" ? true : false;
    this.branchNumber = props.branchNumber;
    this.abnormality = props.abnormality;
    this.biko = props.biko;
    this.denno = props.denno;
    this.gyono = props.gyono;
  }
}

export const KGaikanText = {
  workDate: { text: "作業年月日", value: "workDate" },
  worker: { text: "作業者", value: "worker" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  successFlag: { text: "受注番号品 完了", value: "successFlag" },
  preProcessNo: { text: "前工程作業番号", value: "preProcessNo" },
  specificationNo: { text: "仕様書No", value: "specificationNo" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  suryo: { text: "数量(m)", value: "suryo" },
  suryoMeisai: { text: "数量明細(m)", value: "suryoMeisai" },
  goodOrBad: { text: "良・否", value: "goodOrBad" },
  isRepairing: { text: "処置：修理", value: "isRepairing" },
  isDisassembly: { text: "処置：解体", value: "isDisassembly" },
  isRemanufacture: { text: "処置：廃棄/再製作", value: "isRemanufacture" },
  isPackaging: { text: "製品梱包", value: "isPackaging" },
  branchNumber: { text: "枝番", value: "branchNumber" },
  abnormality: { text: "製品異常処置報告", value: "abnormality" },
  biko: { text: "備考", value: "biko" }
} as const;

export class KGaikan extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  worker: string | null;
  acceptNo: string | null;
  successFlag: string | null;
  preProcessNo: string | null;
  specificationNo: string | null;
  itemName: string | null;
  size: string | null;
  suryo: number | null;
  suryoMeisai: string | null;
  goodOrBad: string | null;
  isRepairing: string | null;
  isDisassembly: string | null;
  isRemanufacture: string | null;
  isPackaging: string | null;
  branchNumber: string | null;
  abnormality: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: KGaikan);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: KGaikan | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof KGaikan) {
      this.postCode = _props.postCode;
      this.worker = _props.worker;
      this.acceptNo = _props.acceptNo;
      this.successFlag = _props.successFlag;
      this.preProcessNo = _props.preProcessNo;
      this.specificationNo = _props.specificationNo;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.suryo = _props.suryo;
      this.suryoMeisai = _props.suryoMeisai;
      this.goodOrBad = _props.goodOrBad;
      this.isRepairing = _props.isRepairing;
      this.isDisassembly = _props.isDisassembly;
      this.isRemanufacture = _props.isRemanufacture;
      this.isPackaging = _props.isPackaging;
      this.branchNumber = _props.branchNumber;
      this.abnormality = _props.abnormality;
      this.biko = _props.biko;
      this.denno = _props.denno;
      this.gyono = _props.gyono;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "KGAIKA";
    this.worker = null;
    this.acceptNo = null;
    this.successFlag = "0";
    this.preProcessNo = null;
    this.specificationNo = null;
    this.itemName = null;
    this.size = null;
    this.suryo = null;
    this.suryoMeisai = null;
    this.goodOrBad = null;
    this.isRepairing = "0";
    this.isDisassembly = "0";
    this.isRemanufacture = "0";
    this.isPackaging = "0";
    this.branchNumber = null;
    this.abnormality = null;
    this.biko = null;
    this.denno = null;
    this.gyono = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
